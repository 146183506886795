.ag-row-pinned {
  .ag-cell {
    @apply tw-border-0;
  }
}

.ag-body-horizontal-scroll-viewport,
.ag-body-vertical-scroll-viewport,
.ag-body-viewport,
.ag-center-cols-viewport,
.ag-floating-bottom-viewport,
.ag-floating-top-viewport,
.ag-header-viewport,
.ag-sticky-bottom-viewport,
.ag-sticky-top-viewport,
.ag-virtual-list-viewport,
.ag-full-width-container {
  // transform: translate3d(0, 0, 0);
}

[data-ref="applyFilterButton"] {
  background: #2979ff;
  border: 1px solid #2979ff;
  color: #fff;
  font-weight: 600;

  &[disabled]:hover {
    cursor: default;
  }

  &:not([disabled]):hover {
    background: #2367d9;
    border: 1px solid #2367d9;
    color: #fff;
  }
}

[data-ref="resetFilterButton"] {
  background: #e2e8f0;
  border: 1px solid #f1f5f9;
  color: #475569;
  font-weight: 600;

  &:hover {
    background: #e2e8f0;
    border: 1px solid #e2e8f0;
    color: #334155;
  }
}

.ag-filter-apply-panel {
  @apply tw-justify-center tw-gap-3;
}

:where(.ag-ltr) .ag-filter-apply-panel-button {
  @apply tw-m-0;
}