.ag-row-pinned .ag-cell {
  @apply tw-border-0;
}

[data-ref=applyFilterButton] {
  background: #2979ff;
  border: 1px solid #2979ff;
  color: #fff;
  font-weight: 600;
}
[data-ref=applyFilterButton][disabled]:hover {
  cursor: default;
}
[data-ref=applyFilterButton]:not([disabled]):hover {
  background: #2367d9;
  border: 1px solid #2367d9;
  color: #fff;
}

[data-ref=resetFilterButton] {
  background: #e2e8f0;
  border: 1px solid #f1f5f9;
  color: #475569;
  font-weight: 600;
}
[data-ref=resetFilterButton]:hover {
  background: #e2e8f0;
  border: 1px solid #e2e8f0;
  color: #334155;
}

.ag-filter-apply-panel {
  @apply tw-justify-center tw-gap-3;
}

:where(.ag-ltr) .ag-filter-apply-panel-button {
  @apply tw-m-0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2J1aWxkcy9lbnRlcnByaXNlc29mdHdhcmUvdGFnLWFwcC13ZWIvc3JjL2Fzc2V0cy9zY3NzL2FnLWdyaWQvYWctZ3JpZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7OztBQWtCSjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7OztBQUlKO0VBQ0U7OztBQUdGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuYWctcm93LXBpbm5lZCB7XG4gIC5hZy1jZWxsIHtcbiAgICBAYXBwbHkgdHctYm9yZGVyLTA7XG4gIH1cbn1cblxuLmFnLWJvZHktaG9yaXpvbnRhbC1zY3JvbGwtdmlld3BvcnQsXG4uYWctYm9keS12ZXJ0aWNhbC1zY3JvbGwtdmlld3BvcnQsXG4uYWctYm9keS12aWV3cG9ydCxcbi5hZy1jZW50ZXItY29scy12aWV3cG9ydCxcbi5hZy1mbG9hdGluZy1ib3R0b20tdmlld3BvcnQsXG4uYWctZmxvYXRpbmctdG9wLXZpZXdwb3J0LFxuLmFnLWhlYWRlci12aWV3cG9ydCxcbi5hZy1zdGlja3ktYm90dG9tLXZpZXdwb3J0LFxuLmFnLXN0aWNreS10b3Atdmlld3BvcnQsXG4uYWctdmlydHVhbC1saXN0LXZpZXdwb3J0LFxuLmFnLWZ1bGwtd2lkdGgtY29udGFpbmVyIHtcbiAgLy8gdHJhbnNmb3JtOiB0cmFuc2xhdGUzZCgwLCAwLCAwKTtcbn1cblxuW2RhdGEtcmVmPVwiYXBwbHlGaWx0ZXJCdXR0b25cIl0ge1xuICBiYWNrZ3JvdW5kOiAjMjk3OWZmO1xuICBib3JkZXI6IDFweCBzb2xpZCAjMjk3OWZmO1xuICBjb2xvcjogI2ZmZjtcbiAgZm9udC13ZWlnaHQ6IDYwMDtcblxuICAmW2Rpc2FibGVkXTpob3ZlciB7XG4gICAgY3Vyc29yOiBkZWZhdWx0O1xuICB9XG5cbiAgJjpub3QoW2Rpc2FibGVkXSk6aG92ZXIge1xuICAgIGJhY2tncm91bmQ6ICMyMzY3ZDk7XG4gICAgYm9yZGVyOiAxcHggc29saWQgIzIzNjdkOTtcbiAgICBjb2xvcjogI2ZmZjtcbiAgfVxufVxuXG5bZGF0YS1yZWY9XCJyZXNldEZpbHRlckJ1dHRvblwiXSB7XG4gIGJhY2tncm91bmQ6ICNlMmU4ZjA7XG4gIGJvcmRlcjogMXB4IHNvbGlkICNmMWY1Zjk7XG4gIGNvbG9yOiAjNDc1NTY5O1xuICBmb250LXdlaWdodDogNjAwO1xuXG4gICY6aG92ZXIge1xuICAgIGJhY2tncm91bmQ6ICNlMmU4ZjA7XG4gICAgYm9yZGVyOiAxcHggc29saWQgI2UyZThmMDtcbiAgICBjb2xvcjogIzMzNDE1NTtcbiAgfVxufVxuXG4uYWctZmlsdGVyLWFwcGx5LXBhbmVsIHtcbiAgQGFwcGx5IHR3LWp1c3RpZnktY2VudGVyIHR3LWdhcC0zO1xufVxuXG46d2hlcmUoLmFnLWx0cikgLmFnLWZpbHRlci1hcHBseS1wYW5lbC1idXR0b24ge1xuICBAYXBwbHkgdHctbS0wO1xufSJdfQ== */